import React from 'react';
import { View, Text, ScrollView, StyleSheet, Image, TouchableOpacity } from 'react-native';

export default function HomeScreen({ navigation }) {
    const sections = [
        { id: 1, title: 'Próximos festejos', image: 'https://via.placeholder.com/300' },
        { id: 2, title: 'El candelero', image: 'https://via.placeholder.com/300' },
        { id: 3, title: 'Ganaderías', image: 'https://via.placeholder.com/300' },
        { id: 4, title: 'Actuantes', image: 'https://via.placeholder.com/300' },
        { id: 5, title: 'Plazas', image: 'https://via.placeholder.com/300' },
    ];

    const ads = [
        { id: 1, content: 'Publicidad 1' },
        { id: 2, content: 'Publicidad 2' },
        { id: 3, content: 'Publicidad 1' },
        { id: 4, content: 'Publicidad 2' },
        { id: 5, content: 'Publicidad 1' },
    ];

    return (
        <View style={styles.container}>
            <ScrollView>
                {sections.map((section, index) => (
                    <View key={section.id} style={styles.section}>
                        <Text style={styles.title}>{section.title}</Text>
                        <Image source={{ uri: section.image }} style={styles.image} />
                        {ads[index] && <Text style={styles.ad}>{ads[index].content}</Text>}
                    </View>
                ))}
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    container: { flex: 1 },
    section: { marginBottom: 20, padding: 10 },
    image: { width: '100%', height: 200, borderRadius: 10 },
    title: { fontSize: 20, fontWeight: 'bold', marginTop: 10 },
    ad: { fontSize: 14, color: 'gray', marginTop: 5 },
});
