import React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';
import Section from '../components/Section';

const newsData = [
  { title: 'Noticia 1' },
  { title: 'Noticia 2' },
  { title: 'Noticia 3' },
  { title: 'Noticia 4' },
];

const FestejosScreen = ({ navigation }) => {
  const renderItem = ({ item }) => (
    <TouchableOpacity style={styles.item}>
      <Text>{item.title}</Text>
    </TouchableOpacity>
  );

  return (
    <Section
      title="Noticias"
      data={newsData}
      renderItem={renderItem}
    />
  );
};

const styles = StyleSheet.create({
  item: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
});

export default FestejosScreen;
