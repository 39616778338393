// UserProfileScreen.js
import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Image,
  TouchableOpacity,
} from 'react-native';

// Componente principal de la pantalla de perfil
const UserProfileScreen = ({ navigation }) => {
  const [profileImage, setProfileImage] = useState(
    'https://placekitten.com/200/200' // Imagen por defecto
  );
  const [socialMediaLink, setSocialMediaLink] = useState('');

  const handleChangeProfileImage = () => {
    // Cambiar la imagen a una URL de ejemplo
    setProfileImage('https://placekitten.com/300/300');
  };

  const handleSaveSocialMedia = () => {
    alert(`Red social agregada: ${socialMediaLink}`);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.heading}>Perfil de Usuario</Text>

      {/* Foto de Perfil */}
      <TouchableOpacity
        style={styles.imageContainer}
        onPress={handleChangeProfileImage}
      >
        <Image source={{ uri: profileImage }} style={styles.profileImage} />
        <Text style={styles.text}>Cambiar Foto de Perfil</Text>
      </TouchableOpacity>

      {/* Campo para agregar redes sociales */}
      <TextInput
        style={styles.input}
        placeholder="Ingresa tu enlace de red social"
        value={socialMediaLink}
        onChangeText={setSocialMediaLink}
      />
      <Button
        title="Guardar enlace"
        onPress={handleSaveSocialMedia}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#f0f0f0',
  },
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  imageContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    marginBottom: 10,
  },
  text: {
    color: '#007BFF',
    textDecorationLine: 'underline',
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
    marginBottom: 10,
    borderRadius: 5,
  },
});

export default UserProfileScreen;
