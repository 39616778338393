import React from 'react';
import { View, StyleSheet, SafeAreaView } from 'react-native';
import BottomBar from './BottomBar';

const Wrapper = ({ children, navigation }) => {
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.contentContainer}>
        {children}
      </View>
      <BottomBar navigation={navigation} />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1, // Asegura que el área principal utilice todo el espacio disponible
    paddingBottom: 60, // Ajuste para que no se sobreponga con la barra
  },
});

export default Wrapper;
