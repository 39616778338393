import React from 'react';
import { View, StyleSheet, TouchableOpacity, Image } from 'react-native';

const BottomBar = ({ navigation }) => {
  return (
    <View style={styles.container}>
      {/* Botón de perfil con la foto */}
      <TouchableOpacity
        style={styles.profileButton}
        onPress={() => navigation.navigate("Profile")} // Abre el menú de usuario
      >
        <Image
          source={{ uri: 'https://via.placeholder.com/30' }} // Imagen de ejemplo para la foto de perfil
          style={styles.image}
        />
      </TouchableOpacity>

      {/* Botón central para ir a Home */}
      <TouchableOpacity
        style={styles.homeButton}
        onPress={() => navigation.navigate('Home')} // Navegar a Home
      >
        <View style={styles.centerButton}>
          <Image
            source={{ uri: 'https://img.icons8.com/ios/50/000000/home.png' }} // Icono de "Home"
            style={styles.icon}
          />
        </View>
      </TouchableOpacity>

      {/* Botón de navegación lateral con el ícono de las tres rayas */}
      <TouchableOpacity
        style={styles.menuButton}
        onPress={() => navigation.openDrawer()} // Navegar hacia el menú lateral
      >
        <View style={styles.menuIcon}>
          <View style={styles.bar} />
          <View style={styles.bar} />
          <View style={styles.bar} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 60,
    backgroundColor: '#ffffff',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: '#ddd',
    paddingHorizontal: 10,
  },
  menuButton: {
    padding: 10,
  },
  menuIcon: {
    width: 20,
    height: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bar: {
    height: 3,
    width: 20,
    backgroundColor: '#333',
    marginVertical: 1,
  },
  profileButton: {
    padding: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: 30,
    width: 30,
    borderRadius: 15,
  },
  homeButton: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
  },
  centerButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#007AFF',
    borderRadius: 20,
  },
  icon: {
    height: 20,
    width: 20,
    tintColor: '#ffffff',
  },
});

export default BottomBar;
