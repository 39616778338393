import React, { useState } from 'react';
import { View, Text, TextInput, FlatList, StyleSheet } from 'react-native';

const Section = ({ title, data, renderItem }) => {
  const [filterText, setFilterText] = useState('');

  // Filtrar datos según el texto en el campo de búsqueda
  const filteredData = data.filter(item =>
    item.title.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <View style={styles.container}>
      {/* Encabezado con el nombre de la sección */}
      <Text style={styles.header}>{title}</Text>

      {/* Campo de búsqueda para filtrar los resultados */}
      <TextInput
        style={styles.searchBar}
        placeholder="Filtrar resultados..."
        value={filterText}
        onChangeText={text => setFilterText(text)}
      />

      {/* Lista dinámica de artículos */}
      <FlatList
        data={filteredData}
        keyExtractor={(item, index) => index.toString()}
        renderItem={renderItem}
        style={styles.list}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  searchBar: {
    height: 40,
    borderWidth: 1,
    borderColor: '#ddd',
    paddingHorizontal: 8,
    borderRadius: 5,
    marginBottom: 10,
  },
  list: {
    flex: 1,
  },
});

export default Section;
