import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

const NavigationMenu = ({ props }) => {
    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={() => props.navigation.navigate("Home")} style={styles.menuItem}>
                <Text style={styles.menuText}>Home</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => props.navigation.navigate("Festejos")} style={styles.menuItem}>
                <Text style={styles.menuText}>Festejos</Text>
            </TouchableOpacity>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
    menuItem: {
        marginVertical: 10,
    },
    menuText: {
        fontSize: 18,
    },
});

export default NavigationMenu;
