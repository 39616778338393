import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import NavigationMenu from '../components/NavigationMenu.js';

import HomeScreen from '../screens/HomeScreen.js';
import UserProfileScreen from '../screens/UserProfileScreen.js';
import FestejosScreen from '../screens/FestejosScreen.js';
import Wrapper from '../components/Wrapper.js';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const MainStack = () => {
    return (
        <Stack.Navigator initialRouteName="Home" screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Home">
            {props => (
                <Wrapper navigation={props.navigation}>
                    <HomeScreen {...props}/>
                </Wrapper>                
            )}
          </Stack.Screen>
          <Stack.Screen name="Profile">
            {props => (
                <Wrapper navigation={props.navigation}>
                    <UserProfileScreen {...props}/>
                </Wrapper>
            )}
          </Stack.Screen>
          <Stack.Screen name="Festejos">
            {props => (
                <Wrapper navigation={props.navigation}>
                    <FestejosScreen {...props}/>
                </Wrapper>
            )}
          </Stack.Screen>
        </Stack.Navigator>
    );
}

const AppNavigator = () => {
    return (
        <NavigationContainer>
          <Drawer.Navigator initialRouteName="Main" screenOptions={{drawerPosition: 'right'}}>
            <Drawer.Screen name="Main" component={MainStack} options={{drawerItemStyle: { display: 'none' }}}/>
            <Drawer.Screen name="Home" options={{drawerItemStyle: { display: 'none' }}}>
                {props => (
                    <Wrapper navigation={props.navigation}>
                        <HomeScreen {...props}/>
                    </Wrapper>                
                )}
            </Drawer.Screen>
            <Stack.Screen name="Profile" options={{drawerItemStyle: { display: 'none' }}}>
                {props => (
                    <Wrapper navigation={props.navigation}>
                        <UserProfileScreen {...props}/>
                    </Wrapper>
                )}
            </Stack.Screen>
            <Drawer.Screen name="Festejos" component={
                (props) => 
                    <Wrapper navigation={props.navigation}>
                        <FestejosScreen {...props}/>
                    </Wrapper>
            }/>
          </Drawer.Navigator>
        </NavigationContainer>
      );
}

export default AppNavigator;
